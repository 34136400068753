/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      {/* <title>Orlovage - backbone of influencers face of the brands</title> */}
      <meta
        name="title"
        content="Orlovage - backbone of influencers face of the brands"
      />
      <meta
        name="description"
        content="Orlovski Agency aka Orlovage is an agency that helps influencers and brands in marketing via social media."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://orlovage.com/" />
      <meta
        property="og:title"
        content="Orlovage - backbone of influencers face of the brands"
      />
      <meta
        property="og:description"
        content="Orlovski Agency aka Orlovage is an agency that helps influencers and brands in marketing via social media."
      />
      <meta property="og:image" content="https://orlovage.com/og_img.jpg" />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://orlovage.com/" />
      <meta
        property="twitter:title"
        content="Orlovage - backbone of influencers face of the brands"
      />
      <meta
        property="twitter:description"
        content="Orlovski Agency aka Orlovage is an agency that helps influencers and brands in marketing via social media."
      />
      <meta
        property="twitter:image"
        content="https://orlovage.com/og_img.jpg"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
