import React from "react"

import { Link } from "gatsby"
import { FooterWrapper, ContactButton } from "./Footer.styles"

export default () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     logo: file(relativePath: { eq: "logo.png" }) {
  //       childImageSharp {
  //         fixed(width: 215) {
  //           ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //         }
  //       }
  //     }

  //     yt: file(relativePath: { eq: "yt-footer.png" }) {
  //       childImageSharp {
  //         fixed(width: 38) {
  //           ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //         }
  //       }
  //     }

  //     fb: file(relativePath: { eq: "fb-footer.png" }) {
  //       childImageSharp {
  //         fixed(width: 17) {
  //           ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //         }
  //       }
  //     }

  //     instagram: file(relativePath: { eq: "instagram-footer.png" }) {
  //       childImageSharp {
  //         fixed(width: 37) {
  //           ...GatsbyImageSharpFixed_withWebp_tracedSVG
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <FooterWrapper>
      <div className="wrapper">
        <div className="logo">
          {/* <Link to="/"> */}
          <a href="#">
            <img
              alt="logo"
              className="logo"
              src={require("../../images/logo.svg")}
            />
          </a>
          {/* </Link> */}
          <p className="logo-description">
            Don’t let your dreams be dreams... <br />
            Achieve them with our help!
            <br />
            Money for influencer!
            <br />
            Promotion for brands!
          </p>
        </div>
        <div className="socials">
          {/* <a className="social" href="https://facebook.com/">
            <Img fixed={data.fb.childImageSharp.fixed} alt="Facebook" />
          </a>
          <a className="social" href="https://instagram.com/">
            <Img fixed={data.instagram.childImageSharp.fixed} alt="Instagram" />
          </a>
          <a className="social" href="https://youtube.com/">
            <Img fixed={data.yt.childImageSharp.fixed} alt="Youtube" />
          </a> */}
        </div>
        <div className="contact">
          <h4>READY TO GET STARTED?</h4>
          <sub>Let's work together.</sub>
          <Link to="/contact">
            <ContactButton>CONTACT US</ContactButton>
          </Link>
        </div>
      </div>
      <div className="copyright-wrapper">
        <div className="copyright">
          © 2020 by Orlovage | <Link to="/privacy">Privacy policy</Link>
        </div>
        <div className="authors">
          Founded by Orlovski Investment
          <br />
          <br />
          Developed by <a href="https://karermedia.pl/">Karer Media</a>
        </div>
      </div>
    </FooterWrapper>
  )
}
