import React from "react"
import PropTypes from "prop-types"

import { ThemeProvider } from "styled-components"
import { createGlobalStyle } from "styled-components"
import theme from "../theme"

import FooterComponent from "./Footer/Footer.component"

const GlobalStyle = createGlobalStyle`
body {
  max-width: 100vw;
  overflow-x: hidden;
}
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <main>{children}</main>
      <FooterComponent />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
