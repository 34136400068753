import styled from "styled-components"
import { Button } from "antd"

export const FooterWrapper = styled.footer`
  background: #0b0314;
  padding: 100px 0 45px;

  .wrapper {
    max-width: 1320px;
    gap: 135px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
  }

  .logo-description {
    margin-top: 55px;
    font-family: Raleway;

    font-size: 1.125rem;

    font-weight: 500;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.78;

    letter-spacing: normal;

    text-align: left;

    color: #ffffff;
  }

  .socials {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(3, 1fr);
  }

  .social {
    border: solid 2px #1f142b;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    transition: all 0.2s ease;

    &:hover {
      filter: brightness(125%);
      transform: scale(110%);
      border-color: #c20049;
    }
  }

  .contact {
    h4,
    sub {
      font-family: Raleway;

      font-size: 1.5rem;

      font-weight: 800;

      font-stretch: normal;

      font-style: normal;

      line-height: 1.33;

      letter-spacing: normal;

      color: #ffffff;
      margin-bottom: 0;
    }

    sub {
      font-size: 1.125rem;
      font-weight: 500;
      display: block;
      margin-bottom: 32px;
    }
  }

  .copyright-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 1550px;
    margin: 0 auto;
    font-family: Raleway;

    font-size: 1rem;

    font-weight: 500;

    font-stretch: normal;

    font-style: normal;

    line-height: 1;

    letter-spacing: normal;

    text-align: center;

    color: #ffffff;
    margin-top: 103px;
  }

  .authors {
    text-align: right;
  }

  a {
    text-decoration: underline;
    color: currentColor;
  }

  @media screen and (max-width: 1580px) {
    .copyright-wrapper {
      width: 100%;
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 1320px) {
    .wrapper {
      grid-template-columns: 1fr;
      padding: 25px;
      gap: 15px;
      justify-content: center;
      width: 100%;
    }

    .logo {
      text-align: center;
    }

    .logo-description {
      margin-top: 10px;
      text-align: center;
    }

    .socials {
      gap: 5px;
      justify-content: space-between;
      text-align: center;
      /* justify-content: center; */
      justify-items: center;
      margin-bottom: 25px;
    }

    .social {
      /* width: 64px;
      height: 64px; */
    }

    .contact {
      text-align: center;
    }

    .copyright-wrapper {
      margin-top: 10px;
      flex-direction: column;

      .authors {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
`

export const ContactButton = styled(Button)`
  padding: 23px 48px;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  font-family: Raleway;
  font-size: 1.063rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  line-height: 1;
  --box-shadow-color: #eb3bf1a0;
  box-shadow: 0 0 35px 0px var(--box-shadow-color);
  box-sizing: border-box;
  height: auto;
  background: ${props => props.theme.gradient("right")};
  border-radius: 33px;
  border: none;

  &:hover,
  &:focus {
    border: none;
    color: white;
    background: ${props => props.theme.gradient("right")};
    --box-shadow-color: #eb3bf1c0;
    filter: brightness(110%);
    transform: scale(105%);
  }
`
